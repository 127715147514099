import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Pagination } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "pagination"
    }}>{`Pagination`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Pagination } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=2295%3A0'} storybook={'/?path=/story/pagination-pagination--playground'} vueStorybook={'/?path=/story/pagination-pagination--playground'} name={'Pagination'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Pagination – это компонент, использующийся для навигации между разными страницами контента.`}</p>
    <p>{`Компонент управляется извне. Текущая страница передается в проп `}<inlineCode parentName="p">{`currentPage`}</inlineCode>{`, при клике по кнопкам пагинации срабатывает `}<inlineCode parentName="p">{`onChange`}</inlineCode>{`.`}</p>
    <p>{`С помощью пропа `}<inlineCode parentName="p">{`pageSize`}</inlineCode>{` можно задать количество элементов на одной странице,
пропом `}<inlineCode parentName="p">{`totalCount`}</inlineCode>{` задать общее количество элементов,
пропом `}<inlineCode parentName="p">{`showAround`}</inlineCode>{` можно задать количество кнопок пагинации по бокам от выбранной.`}</p>
    <p>{`Компонент ведет себя так, что всегда отображается одно и то же число кнопок.
Более подробно логика отображения описана в `}<a parentName="p" {...{
        "href": "#%D0%BF%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5"
      }}>{`поведении`}</a></p>
    <Playground __position={1} __code={'() => {\n  const [currentPage, setCurrentPage] = useState(1)\n  return (\n    <Pagination\n      currentPage={currentPage}\n      onChange={setCurrentPage}\n      totalCount={100}\n      pageSize={5}\n      showAround={1}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Pagination,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentPage, setCurrentPage] = useState(1);
        return <Pagination currentPage={currentPage} onChange={setCurrentPage} totalCount={100} pageSize={5} showAround={1} mdxType="Pagination" />;
      }}
    </Playground>
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node={'?node-id=8811%3A28396'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=8811%3A28401'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=8811%3A28403'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Pagination} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      